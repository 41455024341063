<template>
  <div class='border-t bg-white py-8'>
    <div class='layout-container w-full bg-white flex flex-col lg:flex-row gap-y-8 justify-between px-4 lg:px-0 text-xs lg:text-sm text-gray-700'>
      <div>
        <div class='font-semibold text-sm lg:text-base text-gray-900'>The Korean Society of Lipid and Atherosclerosis</div>
        <div class='mt-2 flex flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-36 lg:w-48 flex-shrink-0'>Phone</div>
          <div>+82-2-3272-5330</div>
        </div>
        <div class='mt-2 flex flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-36 lg:w-48 flex-shrink-0'>Fax</div>
          <div>+82-2-3272-5331</div>
        </div>
        <div class='mt-2 flex flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-36 lg:w-48 flex-shrink-0'>E-mail</div>
          <div><a href='mailto:ksla@lipid.or.kr'>ksla@lipid.or.kr</a></div>
        </div>
        <div class='mt-2 flex flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-36 lg:w-48 flex-shrink-0'>Address</div>
          <div>No.707, Mapoacro Tower, 68 Mapo-daero, Mapo-gu, Seoul 04168, Korea</div>
        </div>
        <div class='mt-2 flex flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-36 lg:w-48 flex-shrink-0'>Business Reg. Number</div>
          <div>110-82-60956</div>
        </div>
      </div>
      <div>
        <div class='font-semibold text-sm lg:text-base text-gray-900'>Secretariat of ICoLA 2023</div>
        <div class='mt-2 flex flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-36 lg:w-48 flex-shrink-0'>Phone</div>
          <div>+82-2-6734-1008, 1011</div>
        </div>
        <div class='mt-2 flex flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-36 lg:w-48 flex-shrink-0'>Fax</div>
          <div>+82-2-6734-1009</div>
        </div>
        <div class='mt-2 flex flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-36 lg:w-48 flex-shrink-0'>E-mail</div>
          <div><a href='mailto:icola2023@planbear.co.kr'>icola2023@planbear.co.kr</a></div>
        </div>
      </div>
      <div class='flex flex-col lg:justify-start lg:items-start justify-center items-center'>
        <img 
          :src='eventConfigLogoImageUrl'
          class='h-16 lg:h-12 cursor-pointer mb-12 lg:mb-6'
          @click='goToHomeSiteHome'/>
        <a 
          class='py-2 flex flex-row justify-center items-center gap-x-1 text-center  rounded-md w-full mb-3 border border-gray-100 hover:border-gray-300' 
          href='http://lipidpub.hk-test.co.kr/eng/'
          target='_blank'>
          <globe-alt-icon class='h-5 text-gray-600'/>
          KSoLA HOMEPAGE
        </a>
        <div class='flex flex-row justify-center items-center gap-x-4 lg:gap-x-2 mb-8 lg:mb-4'>
          <img 
            src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/social_instagram.svg'
            class='h-10 cursor-pointer'
            @click='goTo("instagram")' />
          <img 
            src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/social_facebook.svg'
            class='h-10 cursor-pointer'
            @click='goTo("facebook")' />
          <img 
            src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/social_twitter.svg'
            class='h-10 cursor-pointer'
            @click='goTo("twitter")' />
          <img 
            src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/social_kakao.svg'
            class='h-10 cursor-pointer'
            @click='goTo("kakao")' />
        </div>  
        <div class='flex-row justify-center items-center mb-6'>
          <img
            src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/logo_korean_tourism.png'
            class='h-10 hidden' />
          <img
            src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/logo_seoul_goverment.png'
            class='h-10' />
        </div>
        <div v-if='hasValidToken' 
          class='uppercase border px-4 py-2 text-center rounded-md hover:shadow-md cursor-pointer'
          @click='logoutApp'>
          logout
        </div>
      </div>
    </div>
    <div class='layout-container text-center mt-12 text-xs uppercase'>Copyrights 2023 © The Korean Society of Lipid and Atherosclerosis. All rights reserved.</div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { GlobeAltIcon } from '@vue-hero-icons/outline'

export default {
  name: 'Icola2023LayoutFooter',
  components: {
    GlobeAltIcon,
  },
  computed: {
    ...mapState('users', [
      'hasValidToken'
    ]),
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
      'eventMainThemeColor'
    ]),
    footerNavStyle () {
      return {
        '--color': this.eventMainThemeColor
      }
    }
  },
  methods: {
    ...mapActions('users', [
      'logout'
    ]),
    goToHomeSiteHome () {
      window.open('http://lipidpub.hk-test.co.kr/eng/', '_blank')
    },
    goTo (link) {
      switch (link) {
        case 'instagram':
          window.open('https://www.instagram.com/icolaksola/', '_blank')
          break
        case 'facebook':
          window.open('https://www.facebook.com/profile.php?id=100092556776704', '_blank')
          break
        case 'twitter':
          window.open('https://twitter.com/icola2023', '_blank')
          break
        case 'kakao':
          window.open('http://pf.kakao.com/_TEWAG', '_blank')
          break
      }
    },
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
  },
}
</script>
